<template>
    <div>
        <a-modal :title="getTitle" :width="800" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div class="bg-w pd40">
                    <div>

                        <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                            <a-form-model-item ref="title" label="名称" help="20字以内" required prop="title">
                                <a-input v-model="form.title"/>
                            </a-form-model-item>

                            <a-form-model-item ref="module" label="模块名"  prop="module">
                                <a-input v-model="form.module" :disabled="true"/>
                            </a-form-model-item>

                            <a-form-model-item ref="routing_path" required label="URL" help="填写路由地址，例如：/settingAdmin/auth" prop="routing_path">
                                <a-input v-model="form.routing_path"/>
                            </a-form-model-item>
                            <a-form-model-item ref="name" required label="路由简称"  prop="name">
                                <a-input v-model="form.name"/>
                            </a-form-model-item>

                            <a-form-model-item label="父级权限"  required>
                                <a-select v-model="form.pid" placeholder="请选择" show-search option-filter-prop="children">
                                    <a-select-option :value="0" :key="-1">
                                        顶级
                                    </a-select-option>
                                    <a-select-option :value="item.id" v-for="(item,index) in pidList" :key="index">
                                        {{ item.title_show }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                            <a-form-model-item ref="icon" required label="图标" prop="icon">
                                <a-input v-model="form.icon"/>
                            </a-form-model-item>
                            <a-form-model-item ref="routing_key" label="路由索引" help='顶级分类时必填'  prop="routing_key">
                                <a-input v-model="form.routing_key"/>
                            </a-form-model-item>
                            <a-form-model-item ref="sorts" label="排序" prop="sorts">
                                <a-input-number v-model="form.sorts"/>
                            </a-form-model-item>
                            <a-form-model-item label="是否显示" prop="is_show">
                                <a-switch v-model="form.is_show"/>
                            </a-form-model-item>
                            <a-form-model-item label="是否菜单" prop="is_menu">
                                <a-switch v-model="form.is_menu"/>
                            </a-form-model-item>

                            <a-form-model-item label="备注说明" prop="remark">
                                <a-input v-model="form.remark" type="textarea"/>
                            </a-form-model-item>

                        </a-form-model>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        id: {
            type: Number,
            default: 0
        },
        module:{
            type:String,
            default:''
        }
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            other: '',
            rules: {
                title: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                ],
                routing_path: [
                    {required: true, message: '请填写路由地址', trigger: 'blur'},
                ],
                name: [
                    {required: true, message: '请填写路由简称', trigger: 'blur'},
                ],
                icon: [
                    {required: true, message: '请填写图标', trigger: 'blur'},
                ],
            },
            pidList: [],
            form: {
                id:0,
                title: '',
                module: 'platform',
                pid:0,
                routing_path: '',
                routing_key: '',
                name: '',
                icon: '',
                is_menu: true,
                is_show: true,
                sorts: 0,
                remark: '',
            },
        }
    },
    computed: {
        getTitle() {
            if (this.id === 0) {
                return "添加权限";
            } else {
                return "编辑权限"
            }
        }
    },
    created() {
        this.form.id = this.id;
        this.form.module = this.module;
        this.loaddata();
    },
    methods: {
        loaddata() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/authInfo', {
                id: this.form.id,
            }).then(res => {
                if(res.details instanceof Object){
                    if(Object.keys(res.details).length !== 0){
                        res.details.is_menu = !!res.details.is_menu;
                        res.details.is_show = !!res.details.is_show;
                        this.form = res.details;
                        this.form.sorts = res.details.sort;
                    }
                }
                this.pidList = res.adminAuthTree;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },

        /**
         * 提交数据
         */
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (this.confirmLoading === true) return;
                    this.confirmLoading = true;
                    let p = this.form;
                    p.is_menus = this.form.is_menu ? 1 : 0;
                    p.is_shows = this.form.is_show ? 1 : 0;
                    this.$http.api('platform/authSave', p).then(res => {
                        this.$message.success('操作成功', 1, () => {
                            this.confirmLoading = false;
                            this.$emit("ok");
                        })
                    }).catch(res => {
                        console.log(res);
                        this.confirmLoading = false;
                    })
                }
            })
        },

    }
}
</script>

<style>

</style>
