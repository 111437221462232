<template>
    <div>
        <div class="pd30">
            <div class="flex alcenter">
                <a-button type="primary" icon="plus" @click="addPartnerAct()">添加权限</a-button>
            </div>

            <div class="mt20">
                <div class="wxb-table-gray">
                    <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
                             :loading="loading">

                        <div class="flex alcenter center" slot="is_menu" slot-scope="is_menu">
                            <a-tag v-if="is_menu == 1" color="green">
                                是
                            </a-tag>
                            <a-tag v-if="is_menu == 0" color="red">
                                否
                            </a-tag>
                        </div>
                        <div class="flex alcenter center" slot="is_show" slot-scope="is_show">
                            <a-tag v-if="is_show == 1" color="green">
                                显示
                            </a-tag>
                            <a-tag v-if="is_show == 0" color="red">
                                隐藏
                            </a-tag>
                        </div>
                        <template slot="action" slot-scope="record,index">
                            <div class="flex center">
                                <a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="editPartnerAct(record)">
                                                <i class="iconfont ft14 iconedit"></i>
                                                <span class="ml10">编辑</span>
                                            </a>
                                        </a-menu-item>
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="del(record)">
                                                <i class="iconfont ft14 icondelete"></i>
                                                <span class="ml10">删除</span>
                                            </a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </a-table>
                </div>
            </div>

            <div v-if="editPartnerLoading">
                <edit-platform :id="id" :module="module" :visible="editPartnerLoading" @cancel="cancelEditPartner" @ok="okEditPartner"></edit-platform>
            </div>
        </div>
    </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list.js';
import editPlatform from './modal/editPlatform.vue';

export default {
    components:{
        editPlatform,
    },
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            editPartnerLoading:false,
            id:0,
            module:'platform',
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            columns: [
                {title: 'ID',dataIndex: 'id',align: 'center'},
                {title: '名称',dataIndex: 'title',align: 'center'},
                {title: '路由',dataIndex: 'routing_path',align: 'center'},
                {title: '父级权限',dataIndex:'ptitle',align: 'center',ellipsis: true},
                {title: '排序',dataIndex: 'sort',align: 'center'},
                {title: '是否菜单',dataIndex: 'is_menu',align: 'center',scopedSlots: {customRender: 'is_menu'}},
                {title: '是否显示',dataIndex: 'is_show',align: 'center',scopedSlots: {customRender: 'is_show'}},
                {title: '操作',key: 'status',align: 'center',scopedSlots: {customRender: 'action'}},
            ],
            datas: [],
        }
    },
    computed:{
        getAccountUrl(){
            return process.env.VUE_APP_ACCOUNT_URL
        }
    },
    created() {

    },
    methods: {
        getLists(){
            if(this.loading===true) return;
            this.loading=true;
            this.$http.api('platform/authLists',{
                limit:this.pagination.pageSize,
                page:this.pagination.current,
                module:'platform'
            }).then(res=>{
                this.pagination.total=res.total;
                this.datas=res.list;
                this.loading=false;
            }).catch(res=>{
                console.log(res);
                this.loading=false;
            })
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },

        del(record) {
            this.$confirm({
                title: '确认删除条数据吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/delAuth', {
                            rule_id: record.id,
                            module:'platform'
                        }).then(res => {
                            this.$message.success('删除成功', 1, () => {
                                this.getLists();
                            });
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },
        addPartnerAct(){
            this.id=0;
            this.editPartnerLoading=true;
        },
        editPartnerAct(record){
        	this.id=record.id;
        	this.editPartnerLoading=true;
        },
        cancelEditPartner(){
            this.editPartnerLoading=false;
        },
        okEditPartner(){
            this.editPartnerLoading=false;
            this.getLists();
        },
    }
}
</script>

<style>
</style>
